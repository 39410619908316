export const CONFIG = {
  URL: import.meta.env.VITE_URL,
  ENV: import.meta.env.VITE_ENV,
  API_URL: import.meta.env.VITE_API_URL,
  SMARTY_EMBEDDED_KEY: import.meta.env.VITE_SMARTY_EMBEDDED_KEY,
  FULLSTORY_ORG_ID: import.meta.env.VITE_FULLSTORY_ORG_ID,
  PERSONA_ENV: import.meta.env.VITE_PERSONA_ENV,
  LAUNCH_DARKLY_CLIENT_ID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  STRIPE_EST_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_EST_PUBLISHABLE_KEY,
  SKYFLOW_VAULT_ID: import.meta.env.VITE_SKYFLOW_VAULT_ID,
  SKYFLOW_VAULT_URL: import.meta.env.VITE_SKYFLOW_VAULT_URL,
};
