import axios from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "bootstrap-daterangepicker/daterangepicker.css";

import UserToken from "@equidefi/portals/clients/UserToken";

import MaintenanceScreen from "@equidefi/portals/components/MaintenanceScreen";
import {
  useCurrentUser,
  useIdentifyLDUser,
} from "@equidefi/portals/hooks/useUsers";
import { useIntercomMessenger } from "./hooks/useIntercomMessenger";
import InvestmentOverview from "./pages/app/vault/InvestmentOverview";
import VaultPayment from "./pages/app/vault/VaultPayment";
import VaultLayout from "./pages/app/vault/layout";
import MyProfile from "./pages/app/vault/profile";
import Vault from "./pages/app/vault/vault";
import Auth from "./pages/auth";
import ErrorPage from "./pages/ErrorPage";
import Forgot from "./pages/login/forgot";
import Login from "./pages/login/login";
import MFA from "./pages/login/mfa";
import ResetPassword from "./pages/login/reset";
import SupportPageLayout from "./pages/SupportPage";
import SupportPageSuccess from "./pages/SupportPageSuccess";
import SupportPageForm from "./pages/SupportPageForm";
import Workflow from "./pages/workflow";
import { WorkflowAccreditation } from "./pages/workflow/accreditation";
import { AdditionalSigner } from "./pages/workflow/AdditionalSigner";
import { WorkflowAddress } from "./pages/workflow/address";
import { WorkflowAgreements } from "./pages/workflow/agreement";
import WorkflowCompleted from "./pages/workflow/complete";
import WorkflowRootError from "./pages/workflow/error";
import { WorkflowKYC } from "./pages/workflow/kyc";
import { WorkflowPayment } from "./pages/workflow/payment";
import { WorkflowPaymentManual } from "./pages/workflow/payment/PaymentManual";
import WorkflowPaymentProcessing from "./pages/workflow/payment/PaymentProcessing";
import { WorkflowProfile } from "./pages/workflow/profile";
import WorkflowRegistration from "./pages/workflow/registration";
import WorkflowChooseInvestment from "./pages/workflow/registration/ChooseInvestment";
import WorkflowMFA from "./pages/workflow/registration/MFA";
import { WorkflowSigners } from "./pages/workflow/signers";
import { WorkflowSubscription } from "./pages/workflow/subscription";
import WorkflowRedirect from "./pages/workflow/WorkflowRedirect";
import NotFound from "./pages/NotFound";

const App = (props) => {
  const token = useSelector((state) => state.token);

  const { data: me } = useCurrentUser();
  useIdentifyLDUser(me);
  useIntercomMessenger();
  const flags = useFlags();

  if (UserToken.token()) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${UserToken.token()}`;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  if (flags.maintenanceMode) return <MaintenanceScreen />;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/mfa",
      element: <MFA />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/forgot",
      element: <Forgot />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/support",
      element: <SupportPageLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <SupportPageForm />,
          errorElement: <ErrorPage />,
        },
        {
          path: "success",
          element: <SupportPageSuccess />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: "/reset/:userId/:token",
      element: <ResetPassword />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/vault",
      element: <VaultLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          path: "dashboard",
          element: <Vault />,
          errorElement: <ErrorPage />,
        },
        {
          path: "investment/:id",
          element: <InvestmentOverview />,
          errorElement: <ErrorPage />,
        },
        {
          path: "investment/:id/payment",
          element: <VaultPayment />,
        },
        {
          path: "profile",
          element: <MyProfile />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: "/auth/:redirectUrl/:investmentId/:token",
      element: <Auth />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/offerings/:slug",
      element: <Workflow />,
      errorElement: <WorkflowRootError />,
      children: [
        {
          path: "registration",
          element: <WorkflowRegistration />,
        },
        {
          path: "mfa",
          element: <WorkflowMFA />,
        },
        {
          path: "choose",
          element: <WorkflowChooseInvestment />,
        },
        {
          path: ":investment_id",
          children: [
            {
              path: "profile",
              element: <WorkflowProfile />,
            },
            {
              path: "address",
              element: <WorkflowAddress />,
            },
            {
              path: "signers",
              element: <WorkflowSigners />,
            },
            {
              path: "kyc",
              element: <WorkflowKYC />,
            },
            {
              path: "subscription",
              element: <WorkflowSubscription />,
            },
            {
              path: "payment",
              element: <WorkflowPayment />,
            },
            {
              path: "payment/manual",
              element: <WorkflowPaymentManual />,
            },
            {
              path: "payment/processing",
              element: <WorkflowPaymentProcessing />,
            },
            {
              path: "agreement",
              element: <WorkflowAgreements />,
            },
            {
              path: "accreditation",
              element: <WorkflowAccreditation />,
            },
            {
              path: "complete",
              element: <WorkflowCompleted />,
            },
          ],
        },
        {
          path: ":step?",
          element: <WorkflowRedirect />,
        },
      ],
    },
    {
      path: "/investment/signer/:investmentAgreementId/:signatureId",
      element: <AdditionalSigner />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/offerings/:offeringId/*",
      element: <WorkflowRedirect />,
      errorElement: <ErrorPage />,
    },
    {
      path: "*",
      element: <NotFound />,
      errorElement: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
