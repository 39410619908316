import { useMemo } from "react";
import { Box } from "@chakra-ui/react";

export const InvesmtentProgressItem = ({
  next,
  steps,
  step,
  active,
  onClick,
}) => {
  const isCompletedOrNext = steps.indexOf(step) <= steps.indexOf(next);

  const backgroundColor = useMemo(() => {
    if (active) return "white";
    if (isCompletedOrNext) return "equidefi.green";
    return "lightgray";
  }, [active, isCompletedOrNext]);

  const borderColor = useMemo(() => {
    if (!active) return null;
    if (isCompletedOrNext) return "equidefi.green";
  }, [active, isCompletedOrNext]);

  return (
    <Box
      key={step}
      width="full"
      h={{ base: 2, sm: 3 }}
      borderRadius="8px"
      border={active ? "2px solid" : null}
      bgColor={backgroundColor}
      borderColor={borderColor}
      boxSizing="border-box"
      transition="0.1s"
      cursor={isCompletedOrNext && "pointer"}
      onClick={isCompletedOrNext ? onClick : null}
    />
  );
};
