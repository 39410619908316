import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { initialize as initializeFullStory } from "@equidefi/portals/helpers/fullstory";
import { ApiClientContext } from "@equidefi/portals/hooks/useApi";
import { buildLDProvider } from "@equidefi/portals/providers/LaunchDarkly";
import { EquidefiTheme } from "@equidefi/ui";

import App from "./app";
import { ApiInstance } from "./clients/api";
import { CONFIG } from "./constants/config";
import { initialize as initializeIntercom } from "./helpers/intercom";
import { sentryStart } from "./helpers/sentry";
import Store from "./store";

axios.defaults.baseURL = CONFIG.API_URL;

sentryStart();
initializeFullStory();
initializeIntercom();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

const renderApp = async () => {
  const LDProvider = await buildLDProvider(CONFIG.LAUNCH_DARKLY_CLIENT_ID);

  root.render(
    <ApiClientContext.Provider value={ApiInstance}>
      <Provider store={Store}>
        <ChakraProvider
          toastOptions={{
            defaultOptions: {
              isClosable: true,
              duration: 5_000,
              position: "top-right",
              variant: "top-accent",
            },
          }}
          theme={EquidefiTheme}
        >
          <QueryClientProvider client={queryClient}>
            <LDProvider>
              <App />
            </LDProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ChakraProvider>
      </Provider>
    </ApiClientContext.Provider>
  );
};

renderApp();
